<template>
  <div class="rs-score-gauge">
    <VueSvgGauge
      :start-angle="-90"
      :end-angle="90"
      :value="value"
      :separator-step="0"
      :min="0"
      :max="100"
      :gauge-color="[
        { offset: 5, color: '#64B571' },
        { offset: 45, color: '#EED868' },
        { offset: 55, color: '#EED868' },
        { offset: 95, color: '#C1281B' },
      ]"
      :scale-interval="10"
    >
      <!-- <div class="rs-gauge-text">
      <span>{{ value }}</span>
    </div> -->
    </VueSvgGauge>
    <div class="rs-gauge-text">
      <span :class="getRiskClass">{{ value }}</span>
    </div>
    <div class="rs-gauge-indicators">
      <div class="rs-gauge-ind rs-gauge-ind--safe">Low Risk</div>
      <div class="rs-gauge-ind rs-gauge-ind--risk">High Risk</div>
    </div>
  </div>
</template>

<script>
import { VueSvgGauge } from "vue-svg-gauge";

export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    fontColor: {
      type: String,
      default: "inherit",
    },
  },
  components: {
    VueSvgGauge,
  },
  computed: {
    getRiskClass() {
      if (this.value <= 45) {
        return "is-risk--low";
      } else if (this.value > 45 && this.value < 75) {
        return "is-risk--medium";
      } else if (this.value >= 75) {
        return "is-risk--high";
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.rs-score-gauge {
  position: relative;
}

.rs-gauge-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* margin-top: 1.25rem; */
  font-size: 4rem;
  font-weight: 600;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);

  > span {
    &.is-risk {
      &--high {
        color: #c1281b;
      }
      &--medium {
        color: #f39c12;
      }
      &--low {
        color: #64b571;
      }
    }
  }
}

.rs-gauge-indicators {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
}

.rs-gauge-ind {
  font-weight: 600;
  font-size: 0.875rem;
  &--safe {
    color: #64b571;
  }
  &--risk {
    color: #c1281b;
  }
}
</style>
